<script>

import FacebookLogin from './FacebookLogin.vue'
import LoginMail from './LoginMail.vue'
import RegisterMail from './RegisterMail.vue'
import { mapState, mapGetters } from "vuex";
import { IS_CUSTOM } from "@/common/config";

export default {
  name: "LoginHolder2",
  components: {
    FacebookLogin,
    LoginMail,
    RegisterMail,
  },
  props: {
    login_checked: {
      type: Boolean,
      required: false,
      default: true,
    },
    is_register: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data() {
    return {
      invoked_view : undefined,
      load_login: true,
      show_policy_and_terms: false,
      is_custom: IS_CUSTOM,
      show_mail: IS_CUSTOM,
    };
  },
  computed:{
    ...mapState({
      is_logging_in: state => state.auth.is_logging_in,
      preload_data: state => state.auth.preload_data,
    }),
    ...mapGetters({
      has_yks_pendings: "yks/has_pendings",
      has_surv_pendings: "survey/has_pendings",
    }),
    message_already(){
      return false
      if (this.has_yks_pendings)
        return "Solo falta un paso para que tus participación cuente"
      else if (this.has_surv_pendings)
        return "Solo falta un paso para completar tu perfil"
      else
        return false
    },
    company(){
      return this.is_custom ? 'Fiscalía General de Justicia de la Ciudad de México' : 'Yeeko'
    },
    show_register(){
      if (this.invoked_view)
        return this.invoked_view === 'register'
      else 
        return this.is_register
    },
    texts(){
      let txts = {other_view: this.show_register ? 'login' : 'register'}
      if (this.preload_data){
        txts.title = `Hola ${this.preload_data.first_name}`
        txts.facebook_button = 'Registrarme'
        txts.alternative_title = 'completa tus datos y crea tu cuenta'
        // txts.other_title = `Si ya tienes una cuenta en Yeeko, inicia sesión:'
        txts.other_title = `Si ya tienes una cuenta en ${this.company}, inicia sesión:`
        txts.other_button = 'Iniciar sesión'
      }
      else if (this.show_register){
        txts.title = '¡Únete a la comunidad!'
        txts.facebook_button = 'Registrarme'
        txts.alternative_title = 'crea una cuenta con tu correo'
        // txts.other_title = 'Si ya tienes una cuenta en Yeeko, inicia sesión:'
        txts.other_title = `Si ya tienes una cuenta en ${this.company}, inicia sesión:`
        txts.other_button = 'Iniciar sesión'
      }
      else{
        txts.title = this.is_custom
            ? 'Ingresa con tu cuenta'
            : 'Ingresa a través de Facebook'
        txts.subtitle = 'No publicaremos nada a tu nombre'
        txts.facebook_button = 'Iniciar Sesión'
        txts.alternative_title = 'usa tu cuenta de Yeeko'
        txts.other_title = 'Si no tienes una cuenta, ¡Regístrate!'
        txts.other_button = 'Registrarme'
      }
      return txts
    },
  },
  methods: {
    clearLocalStorage() {
      localStorage.clear();
    }
  },
};
</script>

<template>
  <div>
    <div class="my-2 pt-1 text-center text-h4">
      {{message_already || texts.title}}
    </div>
    <div
      v-if="message_already"
      class="my-2 pt-1 text-center grey--text text-h5"
    >{{texts.title}}</div>
    <h4 v-if="texts.subtitle" class="subheading grey--text mt-1 text-center">
      {{texts.subtitle}}
    </h4>
    <template v-if="!is_custom">
      <v-row
        justify="center"
        align-items="center"
        class="mt-5 mb-3"
      >
        <v-col cols="12" align-self="center" class="text-center">
          <v-btn
            color="#4267B2"
            class="Aligner-item facebook-button"
            :loading="is_logging_in && false"
            :disabled="!login_checked"
          >
            <FacebookLogin
              :loginLabel="`${texts.facebook_button} con Facebook`"
            />
          </v-btn>
        </v-col>
        <v-col cols="12" align-self="center" class="text-center">
          <v-btn
            outlined
            target="_blank"
            href="https://m.me/yeekoapp?ref=:1255"
            class="accent--text"
          >¿Cómo funciona?</v-btn>
        </v-col>

      </v-row>
      <v-divider class="mt-6"></v-divider>
      <h4 class="subheading grey--text text--darken-1 mt-6 mb-3 text-center">
        O bien... {{texts.alternative_title}}
      </h4>
    </template>
    <v-row justify="center" v-if="!show_mail">
      <v-col cols="12" align="center" class="my-4">
        <v-btn
          text
          class="accent--text"
          @click="show_mail = true"
        >Iniciar con correo</v-btn>
      </v-col>
    </v-row>
    <template v-else>
      <v-row justify="center">
        <v-col cols="12" sm="9" class="pt-0" style="max-width: 450px">
          <RegisterMail 
            v-if="show_register || preload_data" 
            :preload_data="preload_data"
          />
          <LoginMail v-else/>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-card-actions 
        v-if="!preload_data"
        class="vertical-buttons"
        row wrap align-center 
      >
        <h4 class="subheading grey--text text--darken-1 mt-3">
          {{texts.other_title}}
        </h4>
        <v-flex class="Aligner" xs12 sm6 md6 lg4 my-2>
          <v-btn
            outlined
            @click="invoked_view = texts.other_view"
          >{{texts.other_button}}</v-btn>
        </v-flex>
      </v-card-actions>
    </template>
    <v-card color="grey lighten-5" class="left-par">
      <v-flex pa-3 mt-5>
        <p>Inicia sesión para crear una cuenta en {{company}}</p>
        <p>
          Después podrás crear una página demo o conectar 
          {{company}} a tu página de Facebook
        </p>
        <span slot="label">
          Al iniciar sesión aceptas el
          <a
            v-if="is_custom"
            href="https://fgjcdmx.gob.mx/terminos-y-condiciones"
            target="_blank"
          >Aviso de Privacidad.</a>
          <router-link v-else to="/policies">Aviso de Privacidad.</router-link>
        </span>
      </v-flex>
    </v-card>    
    <v-card color="grey lighten-5" class="left-par" v-if="false">
      <v-flex pa-3 mt-5>
        <span slot="label">
          Al registrarte aceptas el
          <router-link to="/policies">Aviso de Privacidad.</router-link>
        </span>
      </v-flex>
    </v-card>
  </div>
</template>


<style scoped lang="scss">
.vertical-buttons {
  flex-direction: column;
}

.Aligner {
  display: flex;
  align-items: center;
  justify-content: center;
  .Aligner-item {
    display: block;
  }
}

.left-par {
  text-align: left;
}
</style>