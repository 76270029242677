// import dotenv from 'dotenv'
// dotenv.config();
export const API_URL = process.env.VUE_APP_URL;
export const TOKEN_SITE = process.env.VUE_APP_SITE;
export const FACEBOOK_APP_ID = process.env.VUE_APP_FACEBOOK_ID;
export const BASIC_LOGIN = process.env.VUE_APP_BASIC_LOGIN;
export const FULL_LOGIN = process.env.VUE_APP_FULL_LOGIN;
export const LOGIN_PATH = process.env.VUE_APP_LOGIN_PATH
export const IS_CUSTOM = process.env.VUE_APP_IS_CUSTOM === "true";
// export const FACEBOOK_APP_ID = "3736646306609227";
export default API_URL;
