<script>

import router from "@/router";
import { fbLogin } from "@/components/dashboard/login/helpers";
import { mapState,  } from "vuex";

export default {
  name: "ManagePages", 
  data() {
    return {
      button_loading: false,
      fb_reasign_params: {
        scope: `pages_read_engagement, pages_messaging, pages_show_list`,
        return_scopes: true,
        authType: "rerequest",
        enableProfileSelector: true
      },
      all_status:{
        Linked: {
          badge_icon: 'done',
          status_text: 'Conectada',
          badge_color: 'green',
          action: 'openPage',
          action_text: 'Abrir',
          clickeable: true,
          button_simple: true,
          button_icon: 'launch',
        },
        Available: {
          badge_icon: 'facebook',
          status_text: 'Disponible',
          badge_color: '#3498db',
          action: 'linkPageToYeeko',
          action_text: 'Conectar',
          clickeable: false,
          button_color: 'success',
        },
        Demo: {
          badge_icon: 'science',
          status_text: 'Página Demo',
          badge_color: 'primary',
          action: 'openPage',
          action_text: 'Explorar',
          clickeable: true,
          button_simple: true,
        },
        Revoked: {
          badge_icon: 'block',
          status_text: 'Permisos Revocados',
          badge_color: 'orange',
          chip_text: 'Revocado',
          chip_help: 'Algún administrador te ha denegado el acceso',
          clickeable: false,
        },
        Disabled: {
          badge_icon: 'error',
          status_text: 'No disponible',
          badge_color: 'red',
          chip_text: 'No disponible',
          chip_help: 'Esta página ya no está disponible',
          clickeable: false,
        },
      }
    }
  },
  computed: {
    ...mapState({
      pages_premium: state => state.auth.user_details.pages_premium || [],
      is_special_recover: state => state.auth.is_special_recover,
      has_available_pages: state => state.auth.has_available_pages,
      user: state => state.auth.user_details,
    }),
    all_pages(){
      return this.pages_premium.map(page=>{
        let styles = this.all_status[page.page_status]
        return {...page, ...styles, ...{loading: false}}
      })
    },
    total_demos(){
      return this.pages_premium.filter(p=>p.page_status == 'Demo').length
    },
    title_new_demo(){
      let prev_title = `Demo de ${this.user.fullname}`
      if (!this.total_demos)
        return prev_title
      for (var i = this.total_demos; i < 12; i++) {
        let curr_title = i ? prev_title+=` ${i+1}` : prev_title
        if (this.pages_premium.find(page=> page.title === curr_title))
          continue
        else
          return curr_title
      }
      return false
    },
  },
  created(){
    //console.log(this.pages_premium)
  },
  methods: {
    loginFromFB() {
      fbLogin().then(fb_response => {
        //console.log(fb_response)
        this.$store.dispatch("ASIGN_PERM_FACEBOOK", fb_response)
      })
    },
    openPage(page) {
      if (page.clickeable)
        router.push(`/dashboard/${page.pid}/home`)
    },
    actionPage(page) {
      this[page.action](page)
    },
    isClickableCard({ page_status }) {
      return page_status === "Demo" || page_status === "Linked";
    },
    hasSpecificStatus(page, status, answer_1 = true, answer_2 = false) {
      return page["page_status"] === status ? answer_1 : answer_2;
    },
    badgeColor({ page_status }) {
      return page_status !== "Demo" ? "primary" : "#DE006E";
    },
    linkPageToYeeko({ pid }) {
      this.button_loading = true
      this.$store.dispatch("CREATE_PAGE_PREMIUM", pid)
        .catch(err => {
          this.$store.dispatch("NOTIFY_USER", 
            'Hubo un problema al conectar tu página con Yeeko')
          this.button_loading = false
        });
    },
    createDemo() {
      if (this.title_new_demo){
        this.$store.dispatch("CREATE_DEMO_PAGE", { title: this.title_new_demo })
          .then((data) => {
            this.$store.dispatch("NOTIFY_USER", "Página demo creada correctamente")
            this.$store.commit("UPDATE_PAGES_PREMIUM", data)
            return data
          })
          .then((data) => {
            router.push({
              path: `/dashboard/${data["pid"]}/home`
            });
          })
          .catch(error =>
            this.$store.dispatch(
              "NOTIFY_USER",
              `Hubo un problema al crear la página demo. Error: ${error}`
            )
          );
      }
      else
        this.$store.dispatch("NOTIFY_USER", 
          'Ya llegaste al límite de Demos permitidos')
    },    
  },

};
</script>

<template>
  <div class="mb-2">
    <div class="d-flex align-baseline mb-2">
      <h1 class="ml-2" id="title">Tus Páginas:</h1>
      <v-spacer></v-spacer>
      <v-menu 
        v-if="pages_premium.length"
        transition="slide-y-transition" 
        class="mr-2"
        bottom
      >
        <template v-slot:activator="{ on }">
          <v-btn color="accent" dark v-on="on">
            <v-icon left>add</v-icon>
            Agregar Página
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="createDemo">
            <v-list-item-action>
              <v-icon class="primary--text">science</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="primary--text">
                Crear Página Demo
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="loginFromFB">
            <v-list-item-action>
              <v-icon color="#3498db">fab fa-facebook-square</v-icon>
            </v-list-item-action>
            <v-list-item-title style="color: #3498db">
              Reasignar Permisos de Páginas
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <v-divider class="mb-1"></v-divider>
    <v-card 
      v-if="!pages_premium.length"
      class="indigo lighten-5 elevation-1 my-3"
    >
      <v-card-text class="text-center">
        <h2 style="max-width: 440px; margin: 0px auto;">
          Para conectar tus páginas de Facebook, debes 
           asignar permisos a Yeeko
        </h2>
        <v-btn class="mt-3 button-facebook" @click="loginFromFB">
          <v-icon left>fab fa-facebook-square</v-icon>
          Asignar permisos
        </v-btn>
        <h3 class="grey--text mt-6">Para probar cómo funciona:</h3>
        <v-btn 
          outlined
          class="mt-3"
          color="accent"
          @click="createDemo"
        >
          <v-icon left>science</v-icon>
          Crear Página Demo
        </v-btn>
      </v-card-text>
    </v-card>
    <template v-else>
      <v-card 
        v-for="page in all_pages" 
        :key="page['pid']" 
        class="my-3"
        @click="openPage(page)"
        :class="{'pointer':  page.clickeable}"
      >
        <v-row justify="space-between" align="center" class="my-0">
          <v-col cols="2" sm="2" lg="1" class="pl-6">
            <v-badge
              bottom
              avatar
              :color="page.badge_color"
              class="pl-0 pl-sm-1 float-left mt-3 mb-4"
              overlap
            >
              <template v-slot:badge>
                <v-avatar size="16">
                  <v-icon color="white" size="16">
                    {{page.badge_icon}}
                  </v-icon>
                </v-avatar>
              </template>
              <v-avatar tile color="grey lighten-4">
                <img
                  :src="page.picture"
                  :alt="`Logo de ${page.title}`"
                />
              </v-avatar>
            </v-badge>
          </v-col>
          <v-col 
            cols="5"
            sm="6"
            lg="7"
            class="ml--3 py-2" 
            _style="max-width: 320px;"
          >
            <div 
              class="text-h5 font-weight-bold" 
              :class="$vuetify.breakpoint.xsOnly ? 'text-h6' : 'text-h5'"
            >{{page.title}}</div>
            <div class="text-caption grey--text text--darken-1">
              {{page.status_text}}
            </div>
          </v-col>
          
          <v-col
            _style="min-width: 150px;"
            cols="4"
            sm="4"
            lg="3"
            :align="$vuetify.breakpoint.xsOnly ? 'end' : 'center'"
            class="mr--2"
          >
            <template v-if="page.action">
              <v-btn 
                :color="page.button_color || 'accent'"
                :outlined="!page.button_simple"
                :text="page.button_simple"
                @click="actionPage(page)"
              >
                {{page.action_text}}
                <v-icon
                  v-if="page.button_icon"
                  class="ml-2"
                  color="accent"
                >{{page.button_icon}}</v-icon>
              </v-btn>
            </template>
            <v-tooltip top v-else-if="page.chip_text">
              <template v-slot:activator="{ on }">
                <v-chip>
                  {{page.chip_text}}
                </v-chip>
              </template>
              <span>
                {{page.chip_help}}
              </span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card>
    </template>
  </div>
</template>


<style lang="scss" scoped>
.clickable-card {
  cursor: pointer;
}
</style>